@import './src/scss/variables';
@import './src/scss/app';

$total_height: 450px;

.widget__hex {
	&-palette {
	  display: flex;
	  height: $total_height;
	}

	&-color {
    display: flex;
    flex: 1;
    color: $color2 !important;
    justify-content: center;
    position: relative;

    &:hover {
      .item__add {
        opacity: 1;
      }
    }

    .item {
      display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
      gap: 2rem;

      &__actions {
		    display: flex;
		    flex-direction: column;
		    align-items: center;
		    gap: 1rem;
		    opacity: 0;
      }

      &__color {
		    display: flex;
		    flex-direction: column;
		    align-items: center;
		    gap: .5rem;

	      .name {
	        opacity: 1 !important;
	        text-transform: lowercase;
	      }
      }

      &__add {
		    display: flex;
		    flex-wrap: wrap;
		    gap: 1rem;
		    opacity: 0;
      }
    }

    &:hover {
      .item__actions {
        opacity: 1;
      }
    }

    &.show-shades {
      .shades {
        display: flex;
      }

      .item {
        display: none;
      }
    }

    &.from-image {
      cursor: pointer;

      .shades {
        display: none;
      }

      .move-left,
      .move-right {
        display: none;
      }
    }

    &:first-child {
      .shade:first-child {
        border-top-left-radius: 1rem;
      }

      .shade:last-child {
        border-bottom-left-radius: 1rem;
      }

      .move-left {
        opacity: 0;
        pointer-events: none;
        order: 2;
      }

      .move-right {
        order: 1;
			}
    }

    &:last-child {
      .shade:first-child {
        border-top-right-radius: 1rem;
      }

      .shade:last-child {
        border-bottom-right-radius: 1rem;
      }

      .move-right {
        opacity: 0;
        pointer-events: none;
      }
    }

    .shades {
      display: none;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      cursor: pointer;

      .shade {
        flex: 1;
        display: flex;
        padding: 0 .5rem;
        align-items: center;
        justify-content: space-between;

        span {
          color: $color;

          &:not(.current) {
            opacity: 0;
          }
        }

        a.copy {
          opacity: 0;
        }

        &:hover {
          a.copy {
            opacity: 1;
          }

          span {
            opacity: 1;
          }
        }
      }
    }

    a {
      pointer-events: all;
      color: $color !important;
    }
  }
}

.dark {
  a, span {
    color: $color2 !important;
  }
}

.selected {
	.item__actions, .item__add {
		opacity: 1;
	}
}

.canvas {
  height: 550px;
  overflow: auto;

	&__wrap {
	  position: relative;
	  width: 100%;
	  height: 639px;
  }

  #canvas_picker {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEXv7+////9mUzfqAAAAFElEQVQIW2NksN/ISAz+f9CBGAwAxtEddZlnB4IAAAAASUVORK5CYII=);
    width: 1136px;
    height: 100%;
    cursor: crosshair;
    object-fit: cover;
    object-position: center;
  }
}

#canvas_circle {
	display: none;
  position: absolute;
  cursor: grab;
  background: $color2;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  overflow: hidden;
  z-index: 100;
  transition: transform .2s ease;
  transform: translate3d(-50%, -50%, 0) scale(0.3);

  &.dark {
    box-shadow: $color2 0 0 0 5px, rgba(0, 0, 0, .15) 0 0 0 6px, rgba(0, 0, 0, .05) 0 10px 10px -5px;
  }

  box-shadow: $color 0 0 0 5px, rgba(0, 0, 0, .15) 0 0 0 6px, rgba(0, 0, 0, .05) 0 10px 10px -5px;

  &:hover {
    transform: translate3d(-50%, -50%, 0) scale(0.45)
  }

  &:after {
    content: '';
    width: 4px;
    height: 4px;
    border: 1px solid $color;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    border-radius: 0;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: $color2 0 0 0 1px;
    opacity: 0;
    transition: all .2s ease;
  }

  &.is-active {
    cursor: none;
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1.2);
    z-index: 102;

    &:after {
      opacity: 1;
    }

    > div:after {
      opacity: 1;
    }

    > span {
      opacity: 1;
    }
  }

  > div {
    border: 6px solid $color2;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 48px;
      height: 48px;
      box-shadow: inset rgba(0, 0, 0, .15) 0 0 0 1px;
      border-radius: 50%;
      z-index: 10000;
      top: 50%;
      left: 50%;
      margin: -24px;
      opacity: 0;
    }
  }

  > span {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
    mask-image: radial-gradient($color2, $color);
    position: absolute;
    top: 6px;
    left: 6px;
    display: block;
    background: #f7f7f8;
    opacity: 0;

    canvas {
      width: 100%;
      height: 100%;
      image-rendering: pixelated;
    }
  }
}

#canvas_circle_preview {
  display: none;
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}